// import request from '../base'
import { get, put, post, del } from "./base";
// 直播字典
// 获取全部信息
// export function getAll() {
//     return request({
//         methods: 'GET',
//         url: '/live/stream_dictionary/getall'
//     })
// }

// 直播字典
// 获取全部信息
export function getAll() {
    return get('/live/stream_dictionary/getall')
}
//  新增
export function Insert(data) {
    return post('/live/stream_dictionary/insert', data)
}
//修改
export function Edit(data) {
    return post('/live/stream_dictionary/update', data)
}
//删除
export function DEL(id) {
    return get(`/live/stream_dictionary/delete?id=${id}`)
}
// 上移
export function UpMove(id) {
    return get(`/live/stream_dictionary/upmove?id=${id}`)
}
// 下移
export function DownMove(id) {
    return get(`/live/stream_dictionary/downmove?id=${id}`)
}


// 设计字典
// 获取全部信息

export function getAllDesign() {
    return get('/live/directionary/getall')
}
//  新增
export function InsertDesign(data) {
    return post('/live/directionary/insert', data)
}
//修改
export function EditDesign(data) {
    return post('/live/directionary/update', data)
}
//删除
export function DELDesign(id) {
    return get(`/live/directionary/delete?id=${id}`)
}
// 上移
export function UpMoveDesign(id) {
    return get(`/live/directionary/upmove?id=${id}`)
}
// 下移
export function DownMoveDesign(id) {
    return get(`/live/directionary/downmove?id=${id}`)
}

// 获取设计字典为开
export function getList(isOpen) {
    return get(`/live/directionary/getDictionaryList?isOpen=${isOpen}`)

}

// 获取模板
export function gettemplate(data) {
    return get('/live/directionary_template/getpage', data)
}

// 根据备注搜索
export function getSearch(data) {
    return get('/live/directionary_template/search_by_remark', data)
}
// 删除
export function Delete(id) {
    return get(`/live/directionary_template/delete?id=${id}`)
}
// 编辑
export function EditMoban(data) {
    return post('/live/directionary_template/update', data)
}
//上传
export function update(data) {
    return post('/api/admin/common/updateFile', data)
}

// 功能类型管理
// 获取功能类型
export function getFunctionTypes(params) {
    return get('/api/admin/convention_func_type/types', params)
}
//新增功功类型
export function addTypes(data) {
    return post('/api/admin/convention_func_type/type', data)
}
// 修改功能类型
export function Edittypes(id, data) {
    return put(`/api/admin/convention_func_type/type/${id}`, data)
}
// 获取一个
export function getOneType(id) {
    return get(`/api/admin/convention_func_type/type/${id}`)
}
// 删除功能模块
export function delFunction(id) {
    return del(`/api/admin/convention_func_type/type/${id}`)
}

// 工作台获取数据
export function getInfo(id) {
    return get(`/api/admin/score_num_live/getinfo?userId=${id}`)
}

//个人信息
export function getuserInfo(id) {
    return get(`/api/admin/score_num_live/getuserinfo?id=${id}`)
}

// 获取月度之星
export function getMonthstar() {
    return get('/api/admin/get_monthly_start')
}
// 获取某年某月的月度之星
export function getOnStar(data) {
    return get('/api/admin/get_monthly_start', data)
}
// 获取列表
export function getliebiao(params) {
    return get('/api/admin/score_num_live/score_list', params)
}

// 添加月度之星
export function addstar(params) {
    return post('/api/admin/add_monthly_start', params)
}
// 获取会议列表
export function getLiveStream(data) {
    return get('/yun/selectStreamMettingByName', data)
}