<template>
<div class="workbench" style=" margin: -15px -15px;">
  <div class="workbench-top">
    <div style="width: 98%;margin: auto;display: flex;justify-content: space-between">
      <div style="display: flex">
        <div>
          <img style="width: 60px;height: 60px;border-radius: 60px" v-if="form.headImg!=''"  :src="form.headImg">
          <img style="width: 60px;height: 60px;border-radius: 60px" src="../../../assets/logo.png"  v-else >
        </div>
        <div style="margin-left: 20px">
          <h3>{{form.userName}}小主，祝你开心每一天！</h3>
          <span>{{ form.department }}  |  {{ form.roleName }}</span>
        </div>
      </div>
      <div style="display: flex">
        <div style="text-align: center;padding-right: 10px">
          <div>今年直播总场次/总评分
            <a-tooltip placement="top">
              <template slot="title">
                <span>今年直播总场次/总评分</span>
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <h2>{{ yearNumLive}}/{{ yearScore}}分</h2>
        </div>
        <div style="text-align: center;padding-left: 10px;padding-right: 10px;border-left: 1px solid #F0F2F5;border-right: 1px solid #F0F2F5;">
          <div>本月直播场次/待评分
            <a-tooltip placement="top">
              <template slot="title">
                <span>本月直播场次/待评分</span>
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <h2>{{monthNumLive}}/{{ monthNoScoreNumLive }}</h2>
        </div>
        <div style="text-align: center;padding-left: 10px">
          <div>本月当前评分
            <a-tooltip placement="top">
              <template slot="title">
                <span>本月当前评分</span>
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <h2>{{ monthScore  }}</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="workbench-star">
    <div class="workbench-star-cont">
      <div class="workbench-star-cont-title">
        <h3>月度之星</h3>
      </div>
      <div class="img">
        <div style="width: 25%;"  v-for="(val,key) in MonthStar" :key="key"  class="star">
          <!-- 皇冠 -->
          <div class="centers">
            <!-- <admin_con type="icon-huangguan" style="font-size: 25px" v-if="JSON.stringify(val)!=='{}'"></admin_con> -->
            <img v-if="JSON.stringify(val)!=='{}'"  src="../../../assets/u45.png" style="width:26px;height:25px">
            <img  src="../../../assets/u61.png"  v-else style="width:26px;height:25px" >
          </div>
          <!-- 照片 -->
          <div class="centers" v-if="JSON.stringify(val)!=='{}'">
            <img class="center-img"  :src="val.headImg"  v-if="val.headImg!==''"> 
            <img class="center-img"  src="../../../assets/logo.png" v-else>
          </div>
          <div  class="centers" v-else> 
            <img class="center-img" src="../../../assets/u60.png" >
          </div>
          <!-- 月份 -->
          <div class="centers">
            <p>{{key}}月</p>
          </div>
        </div>
        <!-- <div class="centers">
         <div style="margin-right: 25px">
           <div style="width: 100%;display: flex;justify-content: center">
             <admin_con type="icon-huangguan" style="font-size: 25px"></admin_con>
           </div>
           <div class="centers">
             <img class="center-img" src="../../../assets/u52.png" alt="">
           </div>
         </div>
         <div style="margin-left: 25px">
           <div class="centers">
             <admin_con type="icon-huangguan" style="font-size: 25px"></admin_con>
           </div>
           <div class="centers">
             <img class="center-img" src="../../../assets/u6.jpg" alt="">
           </div>
         </div>
        </div> -->
        <!-- <div style="width: 25%;">
          <div class="centers">
            <admin_con type="icon-huangguan" style="font-size: 25px"></admin_con>
          </div>
          <div class="centers">
            <img class="center-img" src="../../../assets/u52.png" alt="">
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div>
    <div class="workbench-content">
      <div class="workbench-content-left">
        <div class="workbench-content-title">
          <h3 style="font-weight:550">我的会议</h3>
         <a>
            <router-link :to="{path:'/conferenceOverview'}"> 
            >>>  查看直播会议总览
            </router-link>
          </a>
        </div>
        <div class="workbench-content-live">
          <div class="live" v-for="(list,index) in live" :key="index">
            <div>
              <img style="width: 180px;height: auto;" :src=list.coverUrl  alt="">
            </div>
            <div  style="margin-left: 20px">
              <div style="font-size:16px">{{ list.title }}</div>
              <div style="color:rgb(145, 140, 140);margin-left:-8px;margin-top:8px;font-size:12px">
              【所属会议】{{ list.mettingTitle}}
              </div>
              <div style="margin-top: 1px">{{list.showTime}}</div>
              <div style="margin-top: 1px">
                <a-tag color="#2db7f5" v-if="list.domainCode=='yunicu'">
                  云ICU
                </a-tag>
                <a-tag color="#2db7f5" v-if="list.domainCode=='yichimeeting'">
                  医驰
                </a-tag>
                <a-tag color="#87d068" v-if="list.type=='CONVENTION'">
                  多会场
                </a-tag>
                <a-tag color="#87d068" v-if="list.type=='SINGLE'">
                  普通小会
                </a-tag>
                <a-tag color="#87d068" v-if="list.type=='SERIES'">
                  小会系列
                </a-tag>
                <a-tag color="#108ee9">
                 {{ list.liveType }}
                </a-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="workbench-content-right">
        <div class="workbench-content-right-title">
          <h3 style="display:flex;font-weight:550">直播评分排名榜
           <!-- <a-date-picker  @change="onChange"  style="display:inline-block;margin-left:76px" :placeholder="shijian.year+'-'+shijian.month" /> -->
           <a-month-picker   style="width:120px;margin-top:10px;margin-left:30%"
           :placeholder="shijian.year+'-'+shijian.month"
            @change="onChange" 
            :disabled-date="disabledDate"
            :defaultPickerValue="moment(shijian.year+'-'+shijian.month)"
            />
          </h3> 
        </div>
        <div class="per" v-for="(item,index) in liebiao" :key="index">
          <div style="display: flex;">
            <div class="per-num" :style="{ backgroundColor: index < liebiao.length ? '#494848': '#F0F2F5',color:index < 3 ? '#fff': '#000',}">{{index+1}}</div>
            <span>{{ item.userName }}</span>
          </div>
          <span>{{ item.score }}</span>
        </div>
        <div>
      <a-button  type="primary" :disabled="btn" @click="submit" style="margin-left:280px" >
          提交
      </a-button> 
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {admin_icon} from '@/utils/myIcon'
import moment from 'moment';

import {getInfo,getuserInfo,getMonthstar,getliebiao,
       addstar,getLiveStream,getOnStar} 
       from '../../../service/dictionary'
export default {
name: "Workbench",
  components:{
    admin_con: admin_icon,
  },
  data(){
    return{
      user:{},
      yearScore: 0.0, //总评分
      monthNoScoreNumLive: 0,  //本月待评分
      monthNumLive: 0, //本月直播场次
      monthScore: 1,  //本月当前评分
      yearNumLive: 1, //今年直播总场次
      form:{},
      MonthStar:{},   //月度之星数组
      liebiao:[], //列表
      live:[],//直播会议
      shijian:{
        year:'',
        month:''
      }, //选择器时间
      starID:'',
      date:'',
      Nowmonth:'', //当前月份
      Nowyear:'', //当前年份
      live:{},     //我的直播会议
      btn:false  
    }
  },
  created(){
  this.user=localStorage.getItem("user_info") ? JSON.parse(localStorage.getItem("user_info")) : {}
  console.log(this.user.id);
  this.load();
  this.GetMessage();
  this.getLastMonth()
  this.paihang();
  this.LiveStream()
  this.getMonthStar()
  this.getOneStar()
  },
  mounted() {
  
  },
  methods: {
    moment,
  // 获取直播数据
   load(){
    getInfo(this.user.id).then((res)=>{
       if(res.code===0){
          this.yearNumLive=res.data.yearNumLive
          this.monthNoScoreNumLive=res.data.monthNoScoreNumLive
          this.monthNumLive=res.data.monthNumLive
          this.yearScore=res.data.yearScore
          this.monthScore=res.data.monthScore
       }
    })
   },
  //  直播列表
   LiveStream(){
    let data={
      name:this.user.user_name
    }
    getLiveStream(data).then((res)=>{
      this.live=res.data
      
    })
   },
  //  获取当前时间的上一个月 默认展示上一个月的列表数据
   getLastMonth() {
    var date = new Date();
    var year = date.getFullYear();   //当前年：四位数字
    var month = date.getMonth();     //当前月：0-11
    
     this.Nowyear=year    //当前年
     this.Nowmonth=month+1 //当前月
    if (month == 0) {   //如果是0，则说明是1月份，上一个月就是去年的12月
        year -= 1;
        month = 12;
    }
    // month = month < 10 ? ('0' + month) : month;   //月份格式化：月份小于10则追加个0
    // let lastYearMonth = year + '-' + month;
    this.shijian.year=year
    this.shijian.month=month
},
  // 个人信息
  GetMessage(){
    getuserInfo(this.user.id).then((res)=>{
      this.form=res.data
    })
  },
  // 获取月度之星
  getMonthStar(){
    getMonthstar().then((res)=>{
      this.MonthStar=res.data;
    })
  },
  // 获取某年某月的月度之星
  getOneStar(){
    let data={
      month:this.shijian.month,
      year:this.shijian.year
    }
    getOnStar(data).then((res)=>{
      if(res.data.length){ //长度为1，不是空 按钮置灰
        this.btn=true
      }
      else{
        this.btn=false  //长度是0 月度之星不为空 按钮高亮
      }
    })
  },
  onChange(data,dateString) {
    const words = dateString.split('-');
    this.shijian.month=words[1]
    this.shijian.year=words[0]
    this.getOneStar()
    this.paihang()
   },
  // /  排行榜数据
    paihang(){
    getliebiao(this.shijian).then((res)=>{
    this.liebiao=res.data
    this.starID=this.liebiao[0].scoreId
   })
  },
  // 提交月度之星
submit(){
  // 两个年份相同
   if(this.shijian.year==this.Nowyear){
    var shjianmonth=this.shijian.month+1
    if(shjianmonth===this.Nowmonth){
     let form={
     starId:this.starID,
     month:this.shijian.month,
     year:this.shijian.year,
     createdById:this.user.id
     }
    addstar(form).then((res)=>{
        if(res.code==0){
          this.$message.success("提交成功")
          this.getMonthStar()
        }
    })
  }
  else{
    this.$message.warning('未到提交时间')
  }
 }
//  年份不同 只有一月才能提交上一年12月
  else if(this.Nowyear>this.shijian.year){
    if(this.Nowmonth==1){
    let form={
    starId:this.starID,
    month:this.shijian.month,
    year:this.shijian.year,
    createdById:this.user.id
    }
    addstar(form).then((res)=>{
     if(res.code==0){
          this.$message.success("提交成功")
          this.getMonthStar()
        }
    })}
    else{
    this.$message.warning('未到提交时间')
  }

    }
  },
  // 禁止选择后面的时间
  disabledDate(current) {
    return current && current > moment().endOf('day');
    },

  },


}

</script>

<style scoped lang="scss">
.workbench{
  background: #F0F2F5;
  .workbench-top{
    height: 100px;
    background-color: #ffff;
    display: flex;
  }
  .workbench-star{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
    .workbench-star-cont{
      width: 98%;margin: auto;background-color: #fff;height: 170px;
      .workbench-star-cont-title{
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid #F0F2F5;
      }
      .img{
        margin: auto;
        padding-top: 15px;
        display: flex;
        justify-content:space-between;
        .star{
          //  width:8%;
          //  height: 90%;
          //  background: red;
           .centers{
          width: 100%;display: flex;justify-content: center;
           .center-img{
            width: 60px;
            border-radius: 60px;
          }
        }
      }
      }
    }
  }

  .workbench-content{
    width: 98%;margin: auto;background-color: #fff;
    display: flex;
    min-height: 600px;
    .workbench-content-left{
      width: 60%;
      .workbench-content-title{
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 1px solid #F0F2F5;
        display: flex;
        justify-content: space-between;
      }
      .workbench-content-live{
        width: 90%;
        margin: auto;
        margin-top: 20px;
        .live{
          padding-top: 15px;
          display: flex;border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;
        }
      }
    }
    .workbench-content-right{
      width: 40%;
      border-left: 10px solid #F0F2F5;
      background-image: url("../../../assets/fendou.png");
      background-repeat: no-repeat;
      background-size: 340px 360px;
      background-position: 70%  100%;
      
      .workbench-content-right-title{
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .per{
        padding: 15px;
        display: flex;
        width: 80%;
        justify-content: space-between;
        .per-num{
          //background-color: #494848;
          width: 20px;
          height: 20px;
          text-align: center;
          //color: #F0F2F5;
          border-radius: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
